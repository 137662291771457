<template>
  <div class="w-100">
    <section>
      <SectionOne />
    </section>
    <section>
      <div class="background-section">
        <SectionTwo />
      </div>
    </section>
    <section>
      <SectionThree />
    </section>
    <section class="background-section">
      <SectionFour />
    </section>
    <section>
      <div class="bg-primary text-white text-center p-6">
        <div class="container">
          <div class="row">
            <div class="col">
              <h2 class="mb-3">
                Vous vous trouvez dans l’une des situations décrites ci-dessus ?
              </h2>
              <p>
                Contactez Yves, notre expert en officine, via le formulaire de
                contact ci-dessous afin de savoir si vous êtes concerné.e par
                les entretiens pharmaceutiques.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SectionOne from '@components/sections/SectionOne.vue';
import SectionTwo from '@components/sections/SectionTwo.vue';
import SectionThree from '@components/sections/SectionThree.vue';
import SectionFour from '@components/sections/SectionFour.vue';

export default {
  components: {
    SectionOne,
    SectionTwo,
    SectionThree,
    SectionFour,
  },
};
</script>

<style lang="scss" scoped>
.background-section {
  background-color: rgba(217, 217, 217, 0.15);
}
</style>
