<template>
  <div class="container my-8">
    <h2 class="text-yellow ml-4 my-5">Sources</h2>
    <div class="row my-5">
      <div class="col">
        <small>
          <ol type="1" class="grey-text">
            <li class="mb-1">
              <a
                target="_blank"
                class="grey-text text-underline"
                href="https://www.omedit-paysdelaloire.fr/lien-ville-hopital/pharmacie-clinique/accompagnements-pharmaceutiques-ville/accompagnements-des-patients-sous-anticancereux-oraux/"
              >
                https://www.omedit-paysdelaloire.fr/lien-ville-hopital/pharmacie-clinique/accompagnements-pharmaceutiques-ville/accompagnements-des-patients-sous-anticancereux-oraux/
              </a>
            </li>
            <li class="mb-1">
              <a
                target="_blank"
                class="grey-text text-underline"
                href="https://www.ameli.fr/pharmacien/exercice-professionnel/sante-prevention/accompagnements/accompagnement-pharmaceutique-patients-chroniques/consignes-aide-facturation#:~:text=La%20prestation%20est%20factur%C3%A9e%20en,70%20%25%20pour%20les%20autres%20th%C3%A8mes"
                >https://www.ameli.fr/pharmacien/exercice-professionnel/sante-prevention/accompagnements/accompagnement-pharmaceutique-patients-chroniques/consignes-aide-facturation#:~:text=La%20prestation%20est%20factur%C3%A9e%20en,70%20%25%20pour%20les%20autres%20th%C3%A8mes</a
              >
            </li>
            <li class="mb-1">
              <a
                target="_blank"
                class="grey-text text-underline"
                href="https://www.france-assos-sante.org/2018/02/13/connaissez-vous-les-entretiens-pharmaceutiques/"
              >
                https://www.france-assos-sante.org/2018/02/13/connaissez-vous-les-entretiens-pharmaceutiques/
              </a>
            </li>
          </ol>
        </small>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
