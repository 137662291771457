<template>
  <div class="container my-8">
    <h2 class="text-primary my-4">
      Combien de temps durent-ils ? <sup><small>1</small></sup>
    </h2>
    <div class="row">
      <div class="col-lg-5 col-12">
        <p class="my-3">
          L’entretien pharmaceutique dure en général entre
          <span class="font-weight-bold">30 et 60 minutes.</span>
          Plusieurs entretiens vous seront proposés en fonction de votre
          situation, afin de faire le point sur vos traitements et répondre à
          toutes vos questions.
        </p>
      </div>
      <div class="col-lg-7 col-12">
        <div class="d-flex align-items-end">
          <img
            src="@img/yves.svg"
            class="img-yves d-lg-block d-none"
            width="196"
          />
          <img
            src="@img/yves.svg"
            class="img-yves d-lg-none d-block"
            width="105"
          />
          <div class="mb-lg-7 mb-0">
            <div class="message-box p-5">
              Ces entretiens pharmaceutiques vous permettent d’avoir une
              <span class="font-weight-bold"
                >relation privilégiée avec votre pharmacien.</span
              >
              C’est un moment
              <span class="font-weight-bold">unique et sur-mesure</span> qui
              vous est dédié afin d’être
              <span class="font-weight-bold">mieux accompagné.e </span>sur votre
              maladie et vos médicaments et de lui poser
              <span class="font-weight-bold">toutes vos questions !</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.message-box {
  position: relative;
  background: #f9af1a;
  border-radius: 20px;
  width: 100%;
  color: #881f77;

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: transparent #f9af1a;
    border-width: 19px 18px 19px 0;
    top: 64%;
    left: -18px;
    margin-top: -19px;
  }
}

.img-yves {
  z-index: 999;
  position: relative;
}
</style>
