<template>
  <div class="container my-8">
    <div class="row">
      <div class="col-lg-6 col-12 px-4">
        <h2 class="text-primary my-4">
          Comment savoir si mon pharmacien réalise des entretiens
          pharmaceutiques ?
        </h2>
        <p>
          Rien de plus simple ! Il vous suffit d’indiquer votre numéro de
          téléphone ci-après et Yves, notre expert en officine, se fera un
          plaisir de vous recontacter dans les meilleurs délais.
        </p>
      </div>
      <div class="col-lg-6 col-12 mt-6 mt-lg-4">
        <input
          v-model="form.phone"
          type="text"
          name="phone"
          class="phone-input w-lg-50 w-100 text-center"
          placeholder="Numéro de téléphone"
        />
        <div v-if="$page.props.errors.phone">
          <p class="text-danger">{{ $page.props.errors.phone }}</p>
        </div>
        <ObsCheckbox id="agb" v-model="form.agb" class="mb-2 my-3 grey-text">
          J'accepte de transmettre les informations saisies à Observia
          (responsable de traitement de ces données) et d’être recontacté dans
          les meilleurs délais*
        </ObsCheckbox>
        <div v-if="$page.props.errors.agb">
          <p class="text-danger">{{ $page.props.errors.agb }}</p>
        </div>
        <p>
          <small class="grey-text my-3"
            >Les informations recueillies sur ce formulaire sont enregistrées
            dans une base de données par Observia à des fins strictement
            internes. Elles seront exclusivement utilisées pour vous communiquer
            les coordonnées d’une pharmacie pouvant vous prodiguer des
            entretiens pharmaceutiques et seront conservées pour un délai
            maximum de 2 ans. Vous pouvez accéder aux informations vous
            concernant, les rectifier ou les supprimer en vous adressant à notre
            DPO via cette adresse dpo@observia-group.com. Retrouvez notre
            politique de confidentialité dans les Mentions légales &
            Cookies</small
          >
        </p>
        <button
          :disabled="form.processing"
          class="btn btn-primary mt-5 w-lg-auto w-100"
          @click.prevent="submit"
        >
          Envoyer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useForm } from '@inertiajs/vue2';
import { Checkbox as ObsCheckbox } from 'design-system';

export default {
  components: {
    ObsCheckbox,
  },
  data() {
    return {
      form: useForm({
        phone: '',
        agb: false,
      }),
    };
  },
  methods: {
    submit() {
      this.form.post('/contact', {
        preserveScroll: true,
        onSuccess: () => this.form.reset(),
      });
    },
  },
};
</script>

<style scoped lang="scss">
.phone-input {
  background: #f5f5f5;
  border-radius: 100px;
  background-image: url('@img/phone.svg');
  background-position: 20px 19px;
  background-repeat: no-repeat;
  border-color: transparent;
  padding: 10px 20px 10px 30px;
}
</style>
