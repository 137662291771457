import Vue from 'vue';
import { createInertiaApp } from '@inertiajs/vue2';
import '@style/app.scss';

import '@plugins/vue-carousel';

createInertiaApp({
  resolve: (name) => {
    const pages = import.meta.glob('./pages/**/*.vue', { eager: true });
    return pages[`./pages/${name}.vue`];
  },
  setup({ el, App, props, plugin }) {
    Vue.use(plugin);

    new Vue({
      render: (h) => h(App, props),
    }).$mount(el);
  },
});
