<template>
  <div>
    <NavbarLayout />
    <Carousel />
    <MainContent />
    <ContactForm />
    <Sources />
    <FooterLayout />
  </div>
</template>

<script>
import ContactForm from '@components/ContactForm.vue';
import MainContent from '@components/MainContent.vue';
import Carousel from '@components/Carousel.vue';
import NavbarLayout from '@components/NavbarLayout.vue';
import FooterLayout from '@components/FooterLayout.vue';
import Sources from '@components/Sources.vue';

export default {
  components: {
    ContactForm,
    MainContent,
    NavbarLayout,
    Carousel,
    FooterLayout,
    Sources,
  },
};
</script>

<style scoped></style>
