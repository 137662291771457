<template>
  <div class="container">
    <h2 class="text-primary text-center py-5">
      Suis-je concerné.e ? <sup><small>3</small></sup>
    </h2>
    <div class="row justify-centent-center py-6">
      <div class="col-lg-3 col-12 text-center">
        <img src="@img/asthme.svg" />
        <h3 class="text-primary py-4">Je suis asthmatique</h3>
      </div>
      <div class="col-lg-3 col-12 text-center">
        <img src="@img/cancer.svg" />
        <h3 class="text-primary py-4">
          Je prends un médicament oral contre le cancer
        </h3>
      </div>
      <div class="col-lg-3 col-12 text-center">
        <img src="@img/age.svg" />
        <h3 class="text-primary py-4">
          J’ai plus de 65 ans et je prends au moins 5 traitements
        </h3>
      </div>
      <div class="col-lg-3 col-12 text-center">
        <img src="@img/coagulant.svg" />
        <h3 class="text-primary py-4">
          Je prends un médicament anti-coagulant
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
