<template>
  <div class="w-100">
    <div class="container w-100">
      <div class="row my-4">
        <div class="col-lg-6 col-12">
          <h2 class="text-primary">
            Un entretien pharmaceutique, qu’est-ce que c’est ?
            <sup><small>1,2</small></sup>
          </h2>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-5 col-12 text-left my-3">
          <div>
            <div class="message-box text-white p-5">
              <p>
                Ce sont des
                <span class="font-weight-bold"
                  >entrevues avec votre pharmacien</span
                >
                pour vous aider à comprendre votre traitement et à renforcer vos
                connaissances sur votre maladie.
              </p>
            </div>
            <img
              src="@img/yves.svg"
              class="img-yves d-lg-block d-none"
              width="196"
            />
            <img
              src="@img/yves.svg"
              class="img-yves d-lg-none d-block"
              width="105"
            />
          </div>
        </div>
        <div class="col-lg-5 col-12 pr-lg-8 pr-0">
          <p>
            Plusieurs sujets seront abordés par votre pharmacien : il vous
            présentera les différents éléments à connaitre sur votre prise en
            charge, vous expliquera les
            <span class="font-weight-bold"
              >effets indésirables de vos médicaments</span
            >
            et la conduite à tenir en cas de survenue, il réalisera avec vous un
            <span class="font-weight-bold">bilan médicamenteux</span>, etc.
            Bref, autant de sujets qui vous permettront de
            <span class="font-weight-bold"
              >mieux comprendre et gérer vos médicaments et votre maladie au
              quotidien !</span
            >
          </p>
          <p>
            Ces entretiens sont
            <span class="font-weight-bold"
              >remboursés à 70 % par l’assurance maladie.</span
            >
            Le reste peut être pris en charge par les mutuelles. Pour
            l’accompagnement des patients atteints de cancer ou sous ALD
            (Affection de Longue Durée), la prise en charge par l’assurance
            maladie est de 100 %.
          </p>
        </div>
        <div class="col-2 text-right d-lg-block d-none">
          <img src="@img/vitale.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.message-box {
  position: relative;
  background: #00a298;
  border-radius: 20px;
  width: 80%;
  left: 10%;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-top-color: #00a298;
    border-bottom: 0;
    margin-left: -60px;
    margin-bottom: -20px;
  }
}

.img-yves {
  margin-top: -20px;
  z-index: 999;
  position: relative;
}
</style>
