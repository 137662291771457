<template>
  <div class="container">
    <div class="row align-items-center py-6">
      <div class="col-lg-6 col-12 text-lg-left text-center">
        <div class="d-lg-block d-none">
          <img src="@img/interview_1.svg" width="425" />
        </div>
        <div class="d-lg-none d-block text-center">
          <img src="@img/interview_1.svg" width="256" />
        </div>
      </div>
      <div class="col-lg-5 col-12">
        <h2 class="text-primary my-4">
          Comment cela se déroule ? <sup><small>3</small></sup>
        </h2>
        <p>
          L’échange se déroule dans
          <span class="font-weight-bold">l’espace de confidentialité</span> de
          la pharmacie. Vous pourrez ainsi discuter et poser vos questions en
          toute tranquillité à votre pharmacien.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
