<template>
  <div class="box-shadow">
    <div class="container py-3">
      <div class="d-flex justify-content-between align-items-center">
        <div><img src="@img/logo_observia.svg" /></div>
        <div class="d-flex align-items-end flex-lg-row flex-column">
          <p class="sandoz-text text-secondary mb-0">
            Avec le soutien institutionnel du laboratoire
          </p>
          <img src="@img/logo_sandoz.svg" class="mb-1 ml-2" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import '@style/variables';

.sandoz-text {
  font-size: 10px;
}

.box-shadow {
  box-shadow: $box-shadow-general;
}
</style>
