<template>
  <div class="img-footer d-flex flex-column justify-content-end w-100 mt-5">
    <div
      class="d-flex p-lg-8 p-2 mt-lg-8 mt-4 flex-lg-row flex-column align-items-center"
    >
      <div>
        <a href="https://www.observia-group.com/fr/">
          <img
            src="@img/logo_observia.svg"
            width="306"
            class="d-lg-block d-none"
          />
        </a>
        <a href="https://www.observia-group.com/fr/">
          <img
            src="@img/logo_observia.svg"
            width="140"
            class="d-lg-none d-block"
          />
        </a>
      </div>
      <div class="ml-lg-8 ml-0 mt-lg-0 mt-2">
        <p class="sandoz-text text-secondary mb-2">
          Avec le soutien institutionnel du laboratoire
        </p>
        <div class="ml-lg-0 ml-3">
          <a href="https://www.sandoz.fr/">
            <img
              src="@img/logo_sandoz.svg"
              width="207"
              class="d-lg-block d-none"
            />
          </a>
          <a href="https://www.sandoz.fr/">
            <img
              src="@img/logo_sandoz.svg"
              width="173"
              class="d-lg-none d-block"
            />
          </a>
        </div>
      </div>
    </div>
    <div
      class="d-flex flex-lg-row flex-column justify-content-between align-items-center p-lg-8 p-4 text-center text-lg-right"
    >
      <a :href="pdf" target="_blank" type="application/pdf"
        ><p class="text-underline">Mentions Légales & Cookies</p></a
      >
      <p>Copyright 2023 Observia Group <br />283319</p>
    </div>
  </div>
</template>

<script>
import PdfLegal from '@assets/pdf/mentions_legales.pdf';

export default {
  data() {
    return {
      pdf: PdfLegal,
    };
  },
};
</script>

<style lang="scss" scoped>
.img-footer {
  background-image: url('@img/footer.svg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 454px;

  @media screen and (max-width: 768px) {
    background-color: #f7f5f5;
    background-image: none;
    height: 100%;
  }
}

.sandoz-text {
  font-size: 12px;
}
</style>
