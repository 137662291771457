<template>
  <div>
    <carousel
      :per-page="1"
      :mouse-drag="false"
      :autoplay="true"
      :pagination-enabled="false"
      :autoplay-timeout="3000"
      :loop="true"
      class="d-lg-block d-none"
    >
      <slide v-for="(image, index) in images" :key="index">
        <div class="bg-primary text-white">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-6 col-12">
                <h3 class="font-weight-light py-2">
                  Votre pharmacien à votre écoute :
                </h3>
                <h2 class="font-weight-bold">
                  bénéficiez d’entretiens pharmaceutiques pour des conseils
                  sur-mesure et éclairés !
                </h2>
              </div>
              <div class="col-lg-6 col-12">
                <img :src="image" />
              </div>
            </div>
          </div>
        </div>
      </slide>
    </carousel>
    <div class="bg-primary text-white d-lg-none d-block py-5">
      <div class="container">
        <div class="row">
          <div class="col">
            <h3 class="font-weight-light py-2">
              Votre pharmacien à votre écoute :
            </h3>
            <h2 class="font-weight-bold">
              bénéficiez d’entretiens pharmaceutiques pour des conseils
              sur-mesure et éclairés !
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import FirstImage from '@img/asthmatic_header.svg';
import SecondImage from '@img/age_header.svg';
import ThirdImage from '@img/cancer_header.svg';

export default {
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      images: [FirstImage, SecondImage, ThirdImage],
    };
  },
};
</script>

<style></style>
